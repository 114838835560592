import {
  HiChartPie,
  HiDocumentText,
  HiLightningBolt,
  HiOutlineKey,
} from "react-icons/hi";

type Menu = {
  label: string;
  icon: any | null;
  href: string | null;
  name: string | null;
  child?: { label: string; name: string; href: string }[];
};

const dashboardMenu = {
  label: "Dasbor",
  name: "dashboard",
  icon: HiChartPie,
  href: "/",
};

const activationCodeMenu = {
  label: "Kode Aktivasi",
  name: "activation-code",
  icon: HiOutlineKey,
  href: "/activation-code",
};

const report = {
  label: "Laporan",
  name: "final-report",
  icon: HiDocumentText,
  href: "/final-report",
};

const PsikologFeeMenu = {
  label: "Psikolog Fee",
  name: "psikolog-fee",
  icon: HiLightningBolt,
  href: "/psikolog-fee",
};

export const menus: Menu[] = [
  dashboardMenu,
  activationCodeMenu,
  report,
  PsikologFeeMenu,
];
