import Guest from "../middleware/guest";
import Private from "../middleware/private";
import Login from "../pages/auth/login";
import { createBrowserRouter } from "react-router-dom";
import Homepage from "../pages/homepage";
import IndexActivationCode from "../pages/activation-codes";
import DetailActivationCode from "../pages/activation-codes/detail";
import SheetActivationCode from "../pages/activation-codes/sheet";
import AccessActivationCode from "../pages/activation-codes/access";
import ProfilePage from "../pages/profile";
import PrintActivationCode from "../pages/activation-codes/print";
import NotFound from "../pages/404";
import IndexFinalReport from "../pages/final-report";
import ReportActivationCode from "../pages/activation-codes/report";
import ShowReport from "../pages/final-report/detail";
import IndexPsikologFee from "../pages/psikolog-fee";

export const routes = createBrowserRouter([
  {
    path: "/auth/login",
    element: (
      <Guest>
        <Login />
      </Guest>
    ),
  },
  {
    path: "/",
    element: (
      <Private>
        <Homepage />
      </Private>
    ),
  },
  {
    path: "/profile",
    element: (
      <Private>
        <ProfilePage />
      </Private>
    ),
  },
  {
    path: "/activation-code",
    element: (
      <Private>
        <IndexActivationCode />
      </Private>
    ),
  },
  {
    path: "/activation-code/:code",
    element: (
      <Private>
        <DetailActivationCode />
      </Private>
    ),
  },
  {
    path: "/activation-code/:code/sheet",
    element: (
      <Private>
        <SheetActivationCode />
      </Private>
    ),
  },
  {
    path: "/activation-code/:code/access",
    element: (
      <Private>
        <AccessActivationCode />
      </Private>
    ),
  },
  {
    path: "/activation-code/:code/print",
    element: (
      <Private>
        <PrintActivationCode />
      </Private>
    ),
  },
  {
    path: "/activation-code/:code/report",
    element: (
      <Private>
        <ReportActivationCode />
      </Private>
    ),
  },
  {
    path: "/final-report",
    element: (
      <Private>
        <IndexFinalReport />
      </Private>
    ),
  },
  {
    path: "/final-report/:code",
    element: (
      <Private>
        <ShowReport />
      </Private>
    ),
  },
  {
    path: "/psikolog-fee",
    element: (
      <Private>
        <IndexPsikologFee />
      </Private>
    ),
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);
